import { FC, SyntheticEvent, useCallback, useState } from "react"
import { ResizeCallbackData, ResizeHandle } from "react-resizable"
import { RezisablePopoverBox } from "./RezisablePopoverBox"

export type Size = { width: number; height: number }
const DEFAULT_MIN_CONSTRAINTS: [width: number, height: number] = [500, 400]
const DEFAULT_MAX_CONSTRAINTS: [width: number, height: number] = [1200, 1000]
const DEFAULT_SIZES: Size = { width: 700, height: 500 }

const RESIZEHANDLES: ResizeHandle[] = ["se","e"]
const SCALE_AXIS = "both"
const HANDLESSIZE: [number, number] = [16, 16]

export const ResizableContainer: FC<{ onResize?: () => void; onResizeStop?: (size: Size) => void }> = ({ children, onResize, onResizeStop }) => {
    const [imageSizes, setImageSizes] = useState(DEFAULT_SIZES)
    const handleResize = useCallback(
        (event: SyntheticEvent, { size }: ResizeCallbackData) => {
            setImageSizes(size) // Aktualisiere die Breite und Höhe im State
            onResize && onResize()
        },
        [setImageSizes, onResize]
    )

    const handleResizeStop = useCallback(
        (event: SyntheticEvent, { size }: ResizeCallbackData) => {
            onResizeStop && onResizeStop(size)
        },
        [onResizeStop]
    )

    return (
        <RezisablePopoverBox
            width={imageSizes.width || DEFAULT_SIZES.width}
            height={imageSizes.height || DEFAULT_SIZES.height}
            onResize={handleResize}
            onResizeStop={handleResizeStop}
            resizeHandles={RESIZEHANDLES}
            minConstraints={DEFAULT_MIN_CONSTRAINTS}
            maxConstraints={DEFAULT_MAX_CONSTRAINTS}
            axis={SCALE_AXIS}
            handleSize={HANDLESSIZE}
        >
            {children}
        </RezisablePopoverBox>
    )
}
