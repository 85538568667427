import { styled } from "@mui/material"
import { FC, HTMLAttributes } from "react"

export const ActionsContainer: FC<HTMLAttributes<HTMLDivElement>> = styled("div")(() => ({
    display: "flex",
    height: 30,
    flex: "1 0 100%",
    flexWrap: "nowrap",
    justifyContent: "space-between",
}))
