import { useState, useCallback } from "react"
import { Skeleton, styled } from "@mui/material"
import { ArticleImage } from "@tm/models"
import { ImageViewerThumbnail } from "./ImageViewerThumbnail"

export const ImagePreviewSkeleton = () => {
    return (
        <div>
            <Skeleton variant="rectangular" sx={{ width: "123px", height: "50px", marginBottom: "0.2em" }} />
            <Skeleton variant="rectangular" sx={{ width: "123px", height: "50px", marginBottom: "0.2em" }} />
        </div>
    )
}

const StyledImagePreview = styled("div")(() => {
    return {
        flex: "0 0 125px",
        marginRight: 8,
        height: "90%",
        overflowY: "auto",
        overflowX: "hidden",
    }
})

type ImagePreviewProps = { startIndex?: number; images: ArticleImage[]; onSelectImage: (image: ArticleImage) => void; isLoading: boolean }
export const ImagePreview = ({ images, onSelectImage, startIndex, isLoading }: ImagePreviewProps) => {
    const [activeIndex, setActiveIndex] = useState<number>(startIndex || 0)

    const handleImageSelect = useCallback(
        (selectedIndex: number) => {
            onSelectImage(images[selectedIndex])
            setActiveIndex(selectedIndex)
        },
        [images, onSelectImage]
    )

    if (images.length <= 1) {
        return null
    }

    return isLoading ? (
        <ImagePreviewSkeleton />
    ) : (
        <StyledImagePreview>
            {images.map((image, index) => (
                <ImageViewerThumbnail key={image.imageUrl} id={index} image={image} isActive={activeIndex === index} onClick={handleImageSelect} />
            ))}
        </StyledImagePreview>
    )
}
