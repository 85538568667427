import { styled } from "@mui/material"
import { ComponentType, DetailedHTMLProps, HTMLAttributes } from "react"

export const DraggableImageContainer = styled("div")(() => ({
    width: "100%",
    height: "calc(100% - 30px)", // calculate height because of the fixed ImageViewerActions height and the resizing option
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    userSelect: "none",
    flex: "70%",
    img: {
        maxWidth: "none",
        "-webkit-user-drag": "none",
        transition: "transform 0.1s ease",
        userSelect: "none",
    },
})) as ComponentType<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
