import { Ref, WheelEvent, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from "react"
import { ArticleImage } from "@tm/models"
import { ViewerImage } from "./components/ViewerImage"
import { ImageViewerActions } from "./components/Actions"
import { ImagePreview } from "./components/Thumbnails"
import { DraggableImageContainer } from "./components/DraggableImageContainer"
import { ImageViewerContainer } from "./ImageViewerContainer"
import { MIN_ZOOM_SCALE, DEFAULT_DRAG_POSITION, MAX_ZOOM_SCALE, WHEEL_ZOOM_STEP } from "./constants"

export type ImageViewerPoverActions = { fitImageToContent: () => void }

export type ImageViewerProps = {
    isLoading: boolean
    images: ArticleImage[]
    startIndex?: number
    actions?: Ref<ImageViewerPoverActions>
    onClose?(): void
}

function roundToOneDecimal(value: number) {
    return Number((Math.floor(value * 10) / 10).toFixed(1))
}

export const ImageViewer = ({ startIndex, images, actions, onClose, isLoading }: ImageViewerProps) => {
    const imageRef = useRef<HTMLImageElement>(null)
    const imageContainerRef = useRef<HTMLDivElement>(null)
    const baseScale = useRef<number>(MIN_ZOOM_SCALE)
    const dragStart = useRef(DEFAULT_DRAG_POSITION)
    const [scale, setScale] = useState<number>(startIndex || MIN_ZOOM_SCALE)
    const [dragging, setDragging] = useState(false)
    const [position, setPosition] = useState(DEFAULT_DRAG_POSITION)
    const [activeImage, setActiveImage] = useState(images[startIndex || 0])

    const zoomInEnabled = useMemo(() => {
        return scale < MAX_ZOOM_SCALE && !dragging
    }, [scale, dragging])

    const zoomOutEnabled = useMemo(() => {
        return scale > (baseScale.current || MIN_ZOOM_SCALE) && !dragging
    }, [scale, dragging])

    const onZoomChange = useCallback((value: number) => {
        setScale((prevScale) => {
            const newScale = Math.min(MAX_ZOOM_SCALE, Math.max(baseScale.current, value)) // Begrenzen auf 1 bis 3
            if (newScale !== prevScale) {
                setPosition({ x: 0, y: 0 }) // Position zurücksetzen beim Skalieren
            }
            return roundToOneDecimal(newScale)
        })
    }, [])

    const onZoomSliderChange = useCallback(
        (e: Event, value: number | number[]) => {
            const updatedScale = Array.isArray(value) ? value[0] : value
            onZoomChange(updatedScale)
        },
        [onZoomChange]
    )

    const onHandleZoomIn = useCallback(() => {
        if (zoomInEnabled) {
            onZoomChange(scale + WHEEL_ZOOM_STEP)
        }
    }, [zoomInEnabled, scale, onZoomChange])

    const onHandleZoomOut = useCallback(() => {
        if (zoomOutEnabled) {
            onZoomChange(scale - WHEEL_ZOOM_STEP)
        }
    }, [zoomOutEnabled, scale, onZoomChange])

    const handleWheel = useCallback(
        (e: WheelEvent) => {
            if (e.deltaY < 0) {
                onHandleZoomIn()
            } else {
                onHandleZoomOut()
            }

            e.stopPropagation()
        },
        [onHandleZoomOut, onHandleZoomIn]
    )

    // DRAGABABILITY
    const handleMouseDown = useCallback(
        (e) => {
            if (scale <= baseScale.current && (imageRef.current?.height || 0) < (imageContainerRef.current?.clientHeight || 1)) {
                return
            }
            setDragging(true)
            dragStart.current = { x: e.clientX - position.x, y: e.clientY - position.y }
        },
        [position.x, position.y, scale]
    )

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            if (!dragging || !imageContainerRef.current || !imageRef.current) {
                return
            }

            // calculate new position depending on the mouseposition
            const movedMouseX = e.clientX - dragStart.current.x
            const movedMouseY = e.clientY - dragStart.current.y

            // at least 20% of the image sould be visible
            const minVisibleWidth = imageContainerRef.current.clientWidth * 0.2
            const minVisibleHeight = imageContainerRef.current.clientHeight * 0.2

            // calculate maxX/Y coordinates to drag the image inside the container boundings
            // this only works for transformOrigin center!
            const maxX = Math.max(0, (imageRef.current.width - minVisibleWidth) / 2)
            const maxY = Math.max(0, (imageRef.current.height - minVisibleHeight) / 2)

            // check top left bottom right if boundings are reached
            const boundedX = Math.max(-maxX, Math.min(movedMouseX, maxX))
            const boundedY = Math.max(-maxY, Math.min(movedMouseY, maxY))

            setPosition({
                x: boundedX,
                y: boundedY,
            })
        },
        [dragging]
    )

    const handleMouseUp = () => {
        setDragging(false)
    }

    const fitImageToContent = useCallback(() => {
        const img = imageRef.current
        const container = imageContainerRef.current

        if (img && container) {
            const heightScale = container.clientHeight / img.clientHeight // calculate scalefactors for height and widht
            const widthScale = container.clientWidth / img.clientWidth
            const updatedScale = Math.min(heightScale, widthScale) // take lowest to fit image to the container

            baseScale.current = roundToOneDecimal(updatedScale) // set new scale and basescale, to be able to set zoom to fitted image
            setScale(baseScale.current)
        }
    }, [imageRef.current, imageContainerRef.current])

    useEffect(() => {
        setActiveImage(images[startIndex || 0])
        setPosition(DEFAULT_DRAG_POSITION)
    }, [images, startIndex])


    useImperativeHandle(actions, () => ({
        fitImageToContent,
    }))

    useLayoutEffect(
        function attachWindowHandlers() {
            // mouse handlers need to be active if cursor leaves the popover
            if (dragging) {
                window.addEventListener("mousemove", handleMouseMove)
                window.addEventListener("mouseup", handleMouseUp)
            } else {
                window.removeEventListener("mousemove", handleMouseMove)
                window.removeEventListener("mouseup", handleMouseUp)
            }

            return () => {
                window.removeEventListener("mousemove", handleMouseMove)
                window.removeEventListener("mouseup", handleMouseUp)
            }
        },
        [dragging, handleMouseMove]
    )

    useLayoutEffect(
        function moveAndScaleImage() {
            if (imageRef.current) {
                imageRef.current.style.transform = `scale(${scale}) translate(${position.x}px, ${position.y}px)`
                imageRef.current.style.transformOrigin = "center"
            }
        },
        [position.x, position.y, scale]
    )

    const onSelectImage = useCallback(
        (image: ArticleImage) => {
            setPosition(DEFAULT_DRAG_POSITION)
            setActiveImage(image)
            fitImageToContent()
        },
        [fitImageToContent]
    )

    const dragCursor = scale !== baseScale.current ? "grab" : undefined

    return (
        <ImageViewerContainer>
            <ImageViewerActions
                baseScale={baseScale.current}
                value={scale}
                handleZoomIn={onHandleZoomIn}
                handleZoomOut={onHandleZoomOut}
                handleChange={onZoomSliderChange}
                onClose={onClose}
                zoomInEnabled={zoomInEnabled}
                zoomOutEnabled={zoomOutEnabled}
            />
            <DraggableImageContainer
                onWheel={handleWheel}
                onMouseDown={handleMouseDown}
                ref={imageContainerRef}
                style={{ cursor: dragging ? "grabbing" : dragCursor }}
            >
                <ViewerImage image={activeImage} isLoading={isLoading} ref={imageRef} onImageLoad={fitImageToContent} />
            </DraggableImageContainer>
            <ImagePreview images={images} onSelectImage={onSelectImage} isLoading={isLoading} />
        </ImageViewerContainer>
    )
}
