import { memo, MouseEvent } from "react"
import { IconButton } from "../../../../generics/button"

import { ZoomSlider } from "./ZoomSlider"
import { ActionsContainer } from "./ActionsWrapper"
import { ZoomContainer } from "./ZoomContainer"
import { ZoomIcon } from "./ZoomIcon"
import { MAX_ZOOM_SCALE, SLIDER_ZOOM_STEP } from "../../constants"

type Props = {
    value: number
    baseScale: number
    handleZoomIn(e: MouseEvent): void
    handleZoomOut(e: MouseEvent): void
    handleChange(event: Event, value: number | number[], activeThumb: number): void

    zoomInEnabled: boolean
    zoomOutEnabled: boolean
    onClose?(): void
}

const SLIDER_WIDTH = 200
export const ImageViewerActions = memo<Props>(
    ({ baseScale, onClose, handleChange, value, handleZoomIn, handleZoomOut, zoomInEnabled, zoomOutEnabled }: Props) => {
        return (
            <ActionsContainer className="image-viewer__actions">
                <ZoomContainer>
                    <IconButton disableRipple onClick={handleZoomOut} disabled={!zoomOutEnabled}>
                        <ZoomIcon name="zoom-out" />
                    </IconButton>
                    <ZoomSlider
                        min={baseScale}
                        max={MAX_ZOOM_SCALE}
                        step={SLIDER_ZOOM_STEP}
                        value={value}
                        onChange={handleChange}
                        aria-labelledby="continuous-slider"
                        style={{ width: SLIDER_WIDTH }}
                    />
                    <IconButton disableRipple onClick={handleZoomIn} disabled={!zoomInEnabled}>
                        <ZoomIcon name="zoom-in" />
                    </IconButton>
                </ZoomContainer>
                {onClose && (
                    <IconButton className="close-btn" onClick={onClose}>
                        <ZoomIcon name="close" />
                    </IconButton>
                )}
            </ActionsContainer>
        )
    }
)
