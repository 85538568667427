import { Popover, PopoverProps } from "@mui/material"
import { ResizableContainer, Size } from "./Resizable"

type ResizablePopoverProps = PopoverProps & {
    onResizeStop?(size: Size): void
    onResize?(): void
    children?: React.ReactNode
}

export const ResizablePopover = ({ onResize, onResizeStop, children, ...popoverProps }: ResizablePopoverProps): JSX.Element | null => {
    if (!popoverProps.open) {
        return null
    }

    return (
        <Popover {...popoverProps}>
            <ResizableContainer onResize={onResize} onResizeStop={onResizeStop}>
                {children}
            </ResizableContainer>
        </Popover>
    )
}
